<template>
    <div>
      <b-card>
            <validation-observer ref="courseFormValidator">  
                <form ref="editForm" >
                    <b-row class="my-1">
                        <b-col  md="3" sm="2">
                            <b-form-group label="Course Name" label-for="courseName">     
                                <span :hidden='editable==false'>
                                <validation-provider
                                    #default="{ errors }"
                                    name="courseName"
                                    rules="required"
                                >
                                <b-form-input
                                    id="courseName"
                                    v-model="editCourse.name"                                    
                                    type="text"  
                                    :disabled='isLoading'
                                    name='courceName'                          
                                    placeholder="Enter Text"
                                    :state="errors.length > 0 ? false:null"
                                    oncopy="return false" 
                                    onpaste="return false" 
                                    autocomplete='off'
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </span>
                                <span v-show="editable==false">{{course.courseName}}</span>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2" class="mb-1 mb-md-0">
                            <b-form-group class="mb-0" label="Field" label-for="field">    
                            <span :hidden='editable==false'>
                                <validation-provider
                                        #default="{ errors }"
                                        name="field"
                                        rules="required"
                                > 
                                <v-select  
                                    v-model="editCourse.field"            
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"                               
                                    :options="editCourse.fieldArry"  
                                    :reduce="name => name.id" label="name"                           
                                    :state="errors.length > 0 ? false:null"
                                    :clearable="false"
                                    Onkeydown="return false"
                                    name="field"
                                    :disabled='isLoading'
                                    read
                                    id='field'                           
                                    class="per-page-selector d-inline-block mx-10 w-100"
                                    oncopy="return false" 
                                    onpaste="return false"              
                                />    
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </span> 
                            <span v-show="editable==false">
                                {{course.fieldData.name}}
                            </span>                      
                        </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2" class="mb-1 mb-md-0">
                            <b-form-group class="mb-0" label="University Name" label-for="universityName">     
                            <span :hidden='editable==false'>
                            <validation-provider
                                    #default="{ errors }"
                                    name="universityName"
                                    rules="required"
                            > 
                            <v-select  
                                v-model="editCourse.universityName"            
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="editCourse.universityArry"                               
                                :clearable="false"  
                                :reduce="name => name.id" label="name"     
                                :state="errors.length > 0 ? false:null"                     
                                Onkeydown="return false"
                                :disabled='isLoading'
                                name="universityName" 
                                id='universityName'
                                class="per-page-selector d-inline-block mx-7 w-100"
                                oncopy="return false" 
                                onpaste="return false"              
                            /> 
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </span>
                            <span v-show='editable==false'>{{course.universityData.name}}</span>                                
                        </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="Intake" label-for="intake">                        
                            <span :hidden='editable==false'>
                            <b-form-checkbox-group
                                v-model="editCourse.intake"
                                :options="editCourse.intakeOptions"
                                class="demo-inline-spacing"
                                id='intake'
                                :disabled='isLoading'
                                value-field="item"
                                text-field="name"
                            />   
                            </span>
                            <span  v-show='editable==false'>
                                {{course.intakeStr}}
                            </span>             
                        </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">             
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="IELTS/TOEFL Band Scores" label-for="bandScore">     
                            <span :hidden='editable==false'>
                            <b-form-input
                                id="bandScore"
                                v-model="editCourse.bandScore"
                                type="text"   
                                :disabled='isLoading'                        
                                placeholder="Enter Text"
                                oncopy="return false" 
                                onpaste="return false" 
                                @keypress="isOnlyDecimal($event)"
                            />
                            </span>
                            <span  v-show='editable==false'>
                                {{course.bandScores}}
                            </span> 
                        </b-form-group>                 
                        </b-col>                    
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="German Language" label-for="germanLang">    
                                <span :hidden='editable==false'>
                                <b-form-radio-group
                                    v-model="editCourse.germanLang"
                                    :options="editCourse.germanLangOption"
                                    :state="state"
                                    :disabled='isLoading'
                                    class="demo-inline-spacing"
                                    name="radio-validation"
                                >                               
                                </b-form-radio-group>
                                </span>
                                <span  v-show='editable==false'>
                                    {{course.germanLanguage}}
                                </span> 
                            </b-form-group>
                        </b-col>    
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="Mode of Application" label-for="applicationMode">    
                               <span :hidden='editable==false'>
                                <b-form-radio-group
                                    v-model="editCourse.applicationMode"
                                    :options="editCourse.applicationModeOption"                               
                                    class="demo-inline-spacing"
                                    id="applicationMode"
                                    :disabled='isLoading'
                                    name="applicationmode"
                                >                           
                                </b-form-radio-group>
                                </span>
                                <span  v-show='editable==false'>
                                    {{course.modeOfApplication}}
                                </span> 
                            </b-form-group>
                        </b-col>
                        <b-col md="3" sm="2">
                            <b-form-group class="mb-0" label="Application Deacline" label-for="applicationdecline">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Application Decline"
                                    rules="required"
                                >  
                                <span :hidden='editable==false'>
                                <b-form-input
                                    id="example-input"
                                    v-model="editCourse.applicationDecline"
                                    type="date"
                                    placeholder="YYYY-MM-DD"
                                    autocomplete="off"
                                    show-decade-nav
                                    :disabled='isLoading'
                                    @click="changeDate"
                                    :state="errors.length > 0 ? false:null"
                                    :max="new Date()"
                                     class="input-date"
                                />
                                </span>
                                <span  v-show='editable==false'>
                                    {{course.declineDate}}
                                </span> 
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider> 
                            </b-form-group>
                        </b-col>    
                    </b-row>
                    <b-row class="my-1">
                        <b-col  md="6" sm="2">
                            <b-form-group class="mb-0" label="Remark" label-for="remark">  
                                <span :hidden='editable==false'>   
                                <b-form-textarea
                                id="textarea-small"
                                size="sm"
                                :disabled='isLoading'
                                v-model="editCourse.remark"
                                placeholder="Enter Text"
                            ></b-form-textarea> 
                            </span>
                                <span  v-show='editable==false'>
                                    {{course.remark}}
                                </span>  
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>
            </validation-observer>
        </b-card>
    </div>
</template>
<script>
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import {
 BCard,
 BCol,
 BRow,
 BFormGroup,
 BFormInput,
 BFormCheckboxGroup,
 BFormRadioGroup,
 BFormTextarea,
 BFormDatepicker
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// FIREBASE IMPORT
import firebase from "firebase"
import { dbCollections } from '@/utils/firebaseCollection.js';
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import { ValidationFunction } from '@/utils/globalValidations.js';
import VSelect from 'vue-select'
import moment from 'moment'
extend('required',required)
export default{
    components: { 
    ValidationProvider,
    ValidationObserver,   
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    VSelect,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormTextarea,
    BFormDatepicker   
   },
    data(){
        return{
            editCourse:{
                name:'',
                field:'',
                fieldArry:[],
                universityName:'',
                universityArry:[],
                intake:['WS'],
                intakeOptions: [
                    { item: 'SS', name: 'SS' },
                    { item: 'WS', name: 'WS' },                  
                ],
                bandScore:'',
                germanLang:'None',
                germanLangOption:[
                    { text: 'None', value: 'None' },
                    { text: 'A1', value: 'A1' },
                    { text: 'A2', value: 'A2' },
                    { text: 'B1', value: 'B1' },
                    { text: 'B2', value: 'B2' },
                ],   
                applicationMode:'Uniassist',
                applicationModeOption:[
                    { text: 'Uniassist', value: 'Uniassist' },
                    { text: 'Direct', value: 'Direct' },                 
                ],
                applicationDecline:'',
                remark:''
            },
            course:{},
            editable:false,
            isLoading:false,
            allFields:[],
            allUniversity:[]
        }
    },
    watch: {
    $route: function () {        
        this.getData();          
    }
    },
    created(){
        this.getData();
        var courceId = this.$route.params.courceId; 
        if(this.editable == false){
             this.$root.$emit('courceEdit',this.editable)
        }
        //Bind edit cource detailes
        this.$root.$on('editCourse',()=>{
            this.editable = true;
            this.editCourse.name=this.course.courseName;
            this.editCourse.field=this.course.fieldData.name;
            this.editCourse.universityName=this.course.universityData.name;
            this.editCourse.intake=this.course.intake;
            this.editCourse.bandScore=this.course.bandScores;
            this.editCourse.germanLang=this.course.germanLanguage;
            this.editCourse.applicationMode=this.course.modeOfApplication;
            this.editCourse.applicationDecline=this.course.editDateBindFormate;
            this.editCourse.remark=this.course.remark;
            this.$root.$emit('courceEdit',this.editable)
            this.$root.$emit('stopSpinner')
        }) 
        this.$root.$on('canceleditCourse',()=>{
            this.editable = false;
            this.$root.$emit('courceEdit',this.editable)
        })         
        this.$root.$on('editCourseDetailSave',()=>{           
            if(this.$refs.courseFormValidator){
            this.$refs.courseFormValidator.validate().then(success=>{
                if(success)
                {
                    this.isLoading = true;
                    let fieldData = {};
                    let intake = '';
                    let universityData = {};
                    if(this.editCourse.fieldArry.length > 0)
                    {
                       let findFieldInd = this.editCourse.fieldArry.findIndex(ele=>{
                           return ele.id == this.editCourse.field
                       })
                       if(findFieldInd > -1){
                           fieldData = this.editCourse.fieldArry[findFieldInd];
                       } 
                    }
                    if(this.editCourse.universityArry.length > 0)
                    {
                       let findUniInd = this.editCourse.universityArry.findIndex(ele=>{
                           return ele.id == this.editCourse.universityName
                       })
                       if(findUniInd > -1){
                           universityData = this.editCourse.universityArry[findUniInd];
                       } 
                    }
                    if(this.editCourse.intakeOptions.length > 0){                        
                        intake = this.editCourse.intake;
                    }
                    let saveObj = {
                        'applicationDecline': new Date(this.editCourse.applicationDecline) ? new Date(this.editCourse.applicationDecline) : new Date(),
                        'bandScores': this.editCourse.bandScore,
                        'courseName': this.editCourse.name,
                        'fieldData': fieldData,
                        'germanLanguage':this.editCourse.germanLang,
                        'intake':intake,
                        'modeOfApplication':this.editCourse.applicationMode,
                        'remark':this.editCourse.remark,
                        'universityData':universityData,                       
                        'updatedAt' : firebase.firestore.FieldValue.serverTimestamp(),
                        'isDeleted': false,
                    }
                    console.log(saveObj,"RESULT")
                    updateQuery.updateRootCollectionDataByDocId(dbCollections.COURSES,courceId,saveObj,updateRes=>{
                        console.log(updateRes)
                        this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Document updated Successfully',
                                    icon: 'CoffeeIcon',
                                    variant: 'success',                               
                                },
                        })
                        this.isLoading = false
                        this.$root.$emit('stopSpinner')
                        this.resetAllFields();
                        this.$router.push({'name':"course-mgmt"})
                    })                    
                }else{
                    console.log("Fill some require field")
                    this.$root.$emit('stopSpinner')
                }
            })
            }else{
            this.$root.$emit('stopSpinner')
            }
        })    
    },
    computed: {
        state() {
        return Boolean(this.value)
        },
    },
    methods:{
        getData(){
            var self = this;

            getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDS,'isDeleted','==',false,res=>{  
                 if(res){
                    this.allFields = res;
                    this.editCourse.fieldArry = [];
                    if(this.allFields.length > 0){                        
                        this.allFields.forEach(values=>{
                            this.editCourse.fieldArry.push({'name':values.fieldName,'id':values.id})
                        })
                    }
                 }
            })
            getQuery.getDataWithSpecificWhereCondition(dbCollections.UNIVERSITIES,'isDeleted','==',false,res=>{                 
                 if(res){
                    this.allUniversity = res;
                    this.editCourse.universityArry = [];
                    if(this.allUniversity.length > 0){                        
                        this.allUniversity.forEach(values=>{
                            this.editCourse.universityArry.push({'name':values.universityName,'id':values.id})
                        })
                    }
                 }
            })
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.COURSES,this.$route.params.courceId,res=>{
                console.log(res)
                if(res && Object.keys(res).length > 0){
                    self.course={};
                    res.editDateBindFormate =  moment(res.applicationDecline.toDate()).format('YYYY-MM-DD');
                    res.declineDate = moment(res.applicationDecline.toDate()).format('DD MMM,YYYY');
                    res.intakeStr = JSON.stringify(res.intake).replace('[','')
                    res.intakeStr = res.intakeStr.replace(']','')
                    res.intakeStr =  res.intakeStr.split('"').join('')  
                    res.intakeStr =  res.intakeStr.split(',').join('/')  
                    console.log(res,"?")
                    self.course = res;
                }
            })
        },
        changeDate(){
            console.log(this.editCourse.declineDate,"changeDatechangeDate")
        },
         isOnlyDecimal(event) {
            ValidationFunction.OnlyFloatWithNumber(event);
            // ValidationFunction.OnlyFloatWithNumber(event);
        },
        resetAllFields(){
            this.editCourse={
                name:'',
                field:'',
                fieldArry:[],
                universityName:'',
                universityArry:[],
                intake:['WS'],
                intakeOptions: [
                    { item: 'SS', name: 'SS' },
                    { item: 'WS', name: 'WS' },                  
                ],
                bandScore:'',
                germanLang:'None',
                germanLangOption:[
                    { text: 'None', value: 'None' },
                    { text: 'A1', value: 'A1' },
                    { text: 'A2', value: 'A2' },
                    { text: 'B1', value: 'B1' },
                    { text: 'B2', value: 'B2' },
                ],   
                applicationMode:'Uniassist',
                applicationModeOption:[
                    { text: 'Uniassist', value: 'Uniassist' },
                    { text: 'Direct', value: 'Direct' },                 
                ],
                applicationDecline:'',
                remark:''
            };
            this.course={};
            this.allFields=[];
            this.allUniversity=[];

        } 
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.per-page-selector {
  width: 170px;
}
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}


@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
